import React from "react";

export default function BuyCoinsWithRubies({
  pay_amount,
  receive_amount,
  closeModal,
  setIsAnimatingCoins,
  updateUserAmount,
  coinId,
  offerType,
}) {
  function handlePaymentSuccess() {
    setIsAnimatingCoins(true);
    closeModal(true);
    updateUserAmount(coinId, offerType);
    setTimeout(() => {
      setIsAnimatingCoins(false);
    }, 3000);
  }

  return (
    <div className="w-[100%] p-2">
      <div className="shop-content p-4 ml-[7px]">
        <p className="font-anton">SHOP</p>
      </div>
      <div className="flex flex-row items-center justify-around h-[81px] bg-white">
        <img className="w-[38px] h-[38px]" src="../img/public/coin_sm.png" />
        <p className="font-[400] text-main font-[20px] font-anton leading-[15px]">
          BUY x{receive_amount} COINS
        </p>
        <div className="w-[90px] h-[40px] bg-lime flex flex-row items-center justify-center">
          <img
            className="w-[28px] h-[17px] flex flex-row justify-around items-center mr-[5px]"
            src="../img/public/rubin_sm.png"
          />
          <p className="text-main font-[400] font-anton text-[16px] leading-[24px]">
            {pay_amount}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row justify-center items-center mt-[20px]">
        <button
          onClick={handlePaymentSuccess}
          style={{
            width: "290px",
            height: "50px",
            backgroundColor: "#D2FF00",
            color: "#1B062F",
            fontWeight: "400",
            fontFamily: "Anton",
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
          }}
        >
          BUY COINS
        </button>
      </div>
    </div>
  );
}
