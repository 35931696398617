import React from "react";

export default function WeekPass() {
  return (
    <>
      <div className="membership font-anton">BUY VIP MEMBERSHIP CARD</div>
      <div className="grid grid-cols-2 bg-white w-[100%] h-[176px]">
        <div className="pass-container">
          <div className="pass-container-content">
            <div className="flex flex-row w-full justify-start ml-[5px] mb-[6px]">
              <img
                className="w-[20px] h-[20px]"
                src="../img/public/logo_pass.png"
              />
            </div>
            <div className="font-anton pass-content">WEEK PASS</div>
            <div className="flex flex-row w-full justify-end mr-[5px] mt-[6px]">
              <img
                className="w-[20px] h-[20px]"
                src="../img/public/logo_pass.png"
              />
            </div>
          </div>
          <p className="font-anton pass-price-p">₹99</p>
          <button
            className="vip-pass-btn font-anton bg-lime"
            style={{
              width: "144px",
              height: "40px",
              fontWeight: "600",
              backgroundColor: "#D2FF00",
              color: "#1B062F",
              textAlign: "center",
            }}
          >
            BUY
          </button>
        </div>
        <div className="pass-container">
          <div className="pass-container-content">
            <div className="flex flex-row w-full justify-start ml-[5px] mb-[6px]">
              <img
                className="w-[20px] h-[20px]"
                src="../img/public/logo_pass.png"
              />
            </div>
            <div className="font-anton pass-content">MONTH PASS</div>

            <div className="flex flex-row w-full justify-end mr-[5px] mt-[6px]">
              <img
                className="w-[20px] h-[20px]"
                src="../img/public/logo_pass.png"
              />
            </div>
          </div>
          <p className="font-anton pass-price-p">₹199</p>
          <button
            className="vip-pass-btn font-anton bg-lime"
            style={{
              width: "144px",
              height: "40px",
              fontWeight: "600",
              backgroundColor: "#D2FF00",
              color: "#1B062F",
              textAlign: "center",
            }}
          >
            BUY
          </button>
        </div>
      </div>
    </>
  );
}
