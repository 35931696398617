import React from "react";
import GooglePayBtn from "./GooglePayBtn";
import { Link } from "react-router-dom";

export default function PaymentModal({
  reciveAmount,
  payAmount,
  closeModal,
  setIsAnimatingRubies,
  rubiesId,
  updateUserAmount,
  offerType,
}) {
  // function handlePay() {
  //   setIsAnimatingRubies(true);
  //   closeModal(true);
  //   setTimeout(() => {
  //     setIsAnimatingRubies(false);
  //   }, 3000);
  // }

  return (
    <>
      <div className="flex flex-row justify-center items-center h-[70px] gap-2">
        <div className="flex flex-row justify-center items-center gap-1">
          <img className="w-[33px] h-[19px]" src="../img/public/rubin_sm.png" />
          <p className="font-anton text-white text-[20px]">
            BUY x{reciveAmount} RUBIES
          </p>
        </div>
        <p className="font-anton text-[white]">₹{payAmount}</p>
      </div>
      <div className="bg-lime h-[30px] flex flex-row justify-start items-center">
        <p className="text-main font-anton ml-[15px]">PAYMENT METHOD</p>
      </div>
      <div className="h-[100px] flex flex-col justify-start items-center gap-2 p-2">
        <Link
          to="/refundpolicy"
          className="text-[white] mt-auto text-[10px] underline"
          target="_blank"
        >
          Read our policy
        </Link>
        <GooglePayBtn
          price={payAmount}
          closeModal={closeModal}
          setIsAnimatingRubies={setIsAnimatingRubies}
          rubiesId={rubiesId}
          updateUserAmount={updateUserAmount}
          offerType={offerType}
        />
      </div>
    </>
  );
}
