import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserProvider, useUser } from "./UserContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Offers from "./components/Shop/Offers";
import Refund from "./components/Support/Refund";
import RefundPolicy from "./components/Support/RefundPolicy";

const NotFound = lazy(() => import("./components/404"));
const Account = lazy(() => import("./components/Account"));
const Admin = lazy(() => import("./components/Admin"));
const AboutUs = lazy(() => import("./components/AboutUs"));
const Blank = lazy(() => import("./components/Blank"));
const Contact = lazy(() => import("./components/Contact"));
const Games = lazy(() => import("./components/Games"));
//const GetAlerts = lazy(() => import("./components/GetAlerts"));
const HomePage = lazy(() => import("./components/HomePage"));
const Layout = lazy(() => import("./components/Layout"));
const Matches = lazy(() => import("./components/Matches"));
const MatchDetail = lazy(() => import("./components/MatchDetail"));
const Media = lazy(() => import("./components/Media"));
const GoogleNewSignup = lazy(
  () => import("./components/Account/NewAccountGoogle")
);
const News = lazy(() => import("./components/News"));
const OtpNewSignup = lazy(() => import("./components/Account/NewAccountOtp"));
const Podcasts = lazy(() => import("./components/Podcasts"));
const Privacy = lazy(() => import("./components/Privacy"));
const Settings = lazy(() => import("./components/SiteSettings"));
const Signup = lazy(() => import("./components/Account/Signup"));
const Teams = lazy(() => import("./components/Teams"));
const Terms = lazy(() => import("./components/Terms"));
const Video = lazy(() => import("./components/Video"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <HomePage />
      </Layout>
    ),
  },
  {
    path: "/about",
    element: (
      <Layout>
        <AboutUs />
      </Layout>
    ),
  },
  {
    path: "/account",
    element: (
      <Layout>
        <Account />
      </Layout>
    ),
  },
  {
    path: "/account_google_newsignup",
    element: (
      <Layout>
        <GoogleNewSignup />
      </Layout>
    ),
  },
  {
    path: "/account_otp_newsignup",
    element: (
      <Layout>
        <OtpNewSignup />
      </Layout>
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <Layout>
          <Admin />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/match",
    element: (
      <Layout>
        <Matches />
      </Layout>
    ),
  },
  {
    path: "/match/:match_id",
    element: (
      <Layout>
        <MatchDetail />
      </Layout>
    ),
  },
  {
    path: "/matchdetail",
    element: (
      <Layout>
        <MatchDetail />
      </Layout>
    ),
  },
  {
    path: "/media",
    element: (
      <Layout>
        <Media />
      </Layout>
    ),
  },
  {
    path: "/podcasts",
    element: (
      <Layout>
        <Podcasts />
      </Layout>
    ),
  },
  {
    path: "/ipl",
    element: (
      <Layout>
        <Blank />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: (
      <Layout>
        <Signup />
      </Layout>
    ),
  },
  {
    path: "/signup",
    element: (
      <Layout>
        <Signup />
      </Layout>
    ),
  },
  {
    path: "/teams",
    element: (
      <Layout>
        <Teams />
      </Layout>
    ),
  },
  {
    path: "/teams/:team_name",
    element: (
      <Layout>
        <Teams />
      </Layout>
    ),
  },
  {
    path: "/terms",
    element: (
      <Layout>
        <Terms />
      </Layout>
    ),
  },
  {
    path: "/video",
    element: (
      <Layout>
        <Video />
      </Layout>
    ),
  },
  {
    path: "/news",
    element: (
      <Layout>
        <News />
      </Layout>
    ),
  },
  {
    path: "/about",
    element: (
      <Layout>
        <Blank />
      </Layout>
    ),
  },
  {
    path: "/settings",
    element: (
      <Layout>
        <Settings />
      </Layout>
    ),
  },
  {
    path: "/privacy",
    element: (
      <Layout>
        <Privacy />
      </Layout>
    ),
  },
  {
    path: "/contact",
    element: (
      <Layout>
        <Contact />
      </Layout>
    ),
  },
  {
    path: "/games",
    element: (
      <Layout>
        <Games />
      </Layout>
    ),
  },
  {
    path: "*",
    element: (
      <Layout>
        <NotFound />
      </Layout>
    ),
  },
  {
    path: "/shop",
    element: (
      <Layout>
        <Offers />
      </Layout>
    ),
  },
  {
    path: "/refund/:id",
    element: (
      <Layout>
        <Refund />
      </Layout>
    ),
  },
  {
    path: "/refundpolicy/:id",
    element: (
      <Layout>
        <RefundPolicy />
      </Layout>
    ),
  },
  {
    path: "/refundpolicy",
    element: (
      <Layout>
        <RefundPolicy />
      </Layout>
    ),
  },
  {
    path: "/refund",
    element: (
      <Layout>
        <Refund />
      </Layout>
    ),
  },
]);

export const App = () => {
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
};
